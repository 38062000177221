<template>
  <div class="whatsapp-buy bg-light-blue">
    <div class="container d-flex justify-center">
      <div class="d-flex flex-column flex-md-row justify-content-md-center align-items-md-center" data-aos="fade-up"
           data-aos-easing="ease" data-aos-duration="1000">
        <div class="pe-md-4">
          <img src="@/assets/images/buyGif.gif" alt="" style="width: 300px"/>
        </div>
        <div class="ps-md-4 pt-5 pt-md-0" style="max-width: 650px">
          <h1 class="title section-main-title">
            Restock your shop today
          </h1>
          <p class="p">
            Click the link below to a customer support
          </p>
          <a href="https://wa.me/+2349053535333?text=Hello, I saw your ad..... And I am interested in using the Product for my business" target="_blank" class="primary-btn">Connect on WhatsApp</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Typed from "typed.js";

export default {
  name: "WhatsappBuy",
  mounted() {
    // this.initTyped();
  },
  methods: {
    initTyped() {
      const options = {
        strings: ['Buy', 'Fees', 'Eat', 'Rent'],
        typeSpeed: 100,
        backDelay: 2000,
        backSpeed: 100,
        loop: true,
      };
      const typed = new Typed(this.$refs.typed, options);
      typed.start();
    }
  }
}
</script>

<style scoped lang="scss">
.whatsapp-buy {
  padding: 25rem 0 12rem;
  position: relative;
  clip-path: url(#wave-hero);
  margin-top: -290px;
  z-index: 10;
  overflow: hidden;
  background: #fff no-repeat fixed center;

  @media (max-width: 1100px) {
    clip-path: none;
    margin: 0;
    padding: 6rem 0;
  }

  @media (max-width: 1100px) {
    padding: 6rem 0;
    clip-path: none;
    margin: 0;
  }
}

.whatsapp-buy {
  > .container {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: 1100px) {
      flex-direction: column;
      align-items: flex-start;
    }

    img {
      width: 300px;
    }

    .desktop-text {
      @media (max-width: 1100px) {
        display: none;
      }
    }

    .mobile-text {
      display: none;

      @media (max-width: 1100px) {
        display: block;
        font-size: 3rem;
        font-weight: 800;
        padding-bottom: 2rem;
      }
    }

    .hero-image {
      width: 90%;
      transform: translateX(30px);
    }
  }

  .primary-btn {
    background: #3d8d3a;

    &:hover {
      background: darken(#3d8d3a, 10);
    }
  }
}


.circle {
  position: absolute;
  top: 300px;
  left: 400px;
  opacity: .4;
}

.circle img {
  width: 150px;
  animation: spin 10s linear infinite;
}

.circle__two {
  position: absolute;
  bottom: 200px;
  right: 300px;
}

.circle__three {
  position: absolute;
  top: 220px;
  right: 400px;
  //opacity: .5;
  filter: hue-rotate(167deg);
  animation: spin 20s linear alternate-reverse infinite;
}

.circle__three img {
  width: 150px;
}

.circle__four {
  position: absolute;
  top: 200px;
  left: 50px;
  animation: spin 20s linear alternate-reverse infinite;
}

.circle__four img {
  width: 120px;
}

.circle__two img {
  animation: spin 20s linear alternate-reverse infinite;
  width: 120px;
}

.triangle {
  position: absolute;
  left: 50px;
  bottom: 90px;
}

.triangle img {
  filter: saturate(0) brightness(1.3);
  width: 90px;
  animation: spin 4s ease-in-out infinite;
}

.stairs {
  position: absolute;
  top: 400px;
  right: 100px;
}

.stairs img {
  filter: hue-rotate(180deg);
  width: 100px;
}


@media (max-width: 1100px) {
  .circle {
    left: initial;
    right: 10px;

    img {
      width: 80px
    }

    &__two {
      right: 20px;
      left: initial;
      bottom: 2rem;

      img {
        width: 60px;
      }
    }

    &__three {
      display: none;
      left: 20px;
      right: initial;
      top: 20px;
    }

    &__four {
      top: 130px;
      left: 30px;

      img {
        width: 80px;

      }
    }
  }

  .triangle {
    bottom: 20px;
    left: 20px;

    img {
      width: 50px;
    }
  }

  .stairs {
    top: 700px;
    right: 40px;
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

:deep(.typed-cursor) {
  color: #3d8d3a;
}
</style>
