<template>
  <div>
    <Navbar dark />
    <PageHeader
      title="Careers at Clan"
      subtitle="We stay on top of our industry by being experts in yours"
    />
    <OurStory />
    <WhyChooseUs />
    <div class="careers"></div>
    <div class="careers_new">
      <div class="container fw-bold  ">
        <h1 class="section-main-title fs-1">Open Position</h1>
      </div>
      <div>
        <JobDescription />
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import JobDescription from "@/components/careers/JobDescription.vue";
import Footer from "@/components/home/Footer.vue";
import Navbar from "@/components/global/Navbar.vue";
import PageHeader from "@/components/global/PageHeader.vue";
import OurStory from "@/components/careers/OurStory";
import WhyChooseUs from "@/components/careers/WhyChooseUs";

export default {
  components: {
    JobDescription,
    Footer,
    Navbar,
    WhyChooseUs,
    PageHeader,
    OurStory
  }
};
</script>

<style scoped lang="scss">
.careers {
  padding: 20rem 0 0 0;
  clip-path: url(#wave-high-right);
  margin-top: -20rem;
  z-index: 10;
  position: relative;
  background: #fff;
  overflow: hidden;

  @media (max-width: 1100px) {
    padding: 6rem 0;
    clip-path: none;
    margin: 0;
  }
}

.careers_new {
  padding: 0 0 6rem;
  // clip-path: url(#wave-high-right);
  margin-top: -10rem;
  z-index: 11;
  position: relative;
  background: #fff;
  overflow: hidden;

  @media (max-width: 1100px) {
    // padding: 6rem 0;
    clip-path: none;
    margin: 0;
  }
}
</style>
