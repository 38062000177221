<template>
  <Teleport to="body">
    <div class="drawer" :class="{ open: isOpen }">
      <div class="drawer-content">
        <button @click="$emit('close')">
          <i class="fa fa-2x fa-times" aria-hidden="true"></i>
        </button>
        <slot></slot>
      </div>
    </div>
  </Teleport>
</template>

<script>
export default {
  props: {
    isOpen: {
      type: Boolean,
      requied: true,
    },
  },
  emits: ['close'],
}
</script>

<style scoped>
.drawer {
  position: fixed;
  top: 0;
  right: -100%; /* Set initial position outside the viewport */
  width: 60vw;
  height: 100vh;
  background-color: #f5f5f5;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  transition: right 0.3s ease;
  z-index: 999999999;
  bottom: 0;
  overflow-y: scroll;
  scrollbar-width: thin;
  scrollbar-color: #aaa #f5f5f5;
}
.drawer::-webkit-scrollbar {
  width: 6px; /* Set the width of the scrollbar */
}

.drawer::-webkit-scrollbar-thumb {
  background-color: #aaa; /* Color of the thumb (draggable part) */
}

.drawer::-webkit-scrollbar-track {
  background-color: #f5f5f5; /* Color of the track (background) */
}

.drawer.open {
  right: 0;
}

.drawer-content {
  padding: 20px;
}

/* Add any additional styling here */
</style>
