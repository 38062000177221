<template>
  <div class="bg-white">
    <div class="container" data-aos="fade-up" data-aos-easing="ease" data-aos-duration="1000">
      <section class="join-us-section bg-light-blue">
        <img src="/images/fade-background.webp" class="faded-img" alt="">
        <div class="join-us-section-inner">
          <div class="join-us-item">
            <h2 class="section-main-title join">
              Join the clan today
            </h2>
            <p class="p">
              We are just good people doing great stuff, and having a lot of fun.
            </p>
          </div>
          <div class="join-us-item">
            <a href="/contact" class="primary-btn">Join our team</a>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: "JoinUs"
}
</script>
