<template>
  <drawer-component :isOpen="isOpen" @close="$emit('close')">
    <div v-if="item" class="container py-2">
      <div class="my-2 ">
        <h1>{{ item.title }}</h1>
      </div>
      <div class="">
        <p class="posted">Posted: {{ item?.updatedAt?.split('T')[0] }}</p>
        <div class="content" v-html="item.desc"></div>
        <div class="">
          <a :href="item?.form_link" target="_blank" class="btn">Apply Now</a>
        </div>
      </div>
    </div>
  </drawer-component>
</template>

<script>
import DrawerComponent from './DrawerComponent.vue'

export default {
  components: { DrawerComponent },
  name: 'JobDetails',
  props: {
    item: {
      type: Object,
    },
    isOpen: {
      type: Boolean,
      requied: true,
    },
  },
  emits: ['close'],
}
</script>

<style scoped>
.content {
  max-width: 90%;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  line-height: 1.5rem;
}
.posted {
  padding-top: 0.5rem;
}
</style>
