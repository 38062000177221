import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import '@/assets/global.scss';
import emitter from '@/lib/emitter';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { formatCurrency } from "@/lib/utils.js";

const app = createApp(App);

app.AOS = new AOS.init();

app.config.globalProperties.$emitter = emitter;

app.config.globalProperties.$filters = {
  formatCurrency: formatCurrency
};

app.use(router).mount('#app');
