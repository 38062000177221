<template>
  <div class="relative">
    <div class="header" id="particles">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-md-6">
            <h1 class="header-title">Restock your shop, pay small small</h1>
            <p class="header-subtitle">
              Buy that market today, make dollar no go shame you
            </p>
            <a
              href="https://bulk.clan.africa"
              target="_blank"
              class="primary-btn cta"
            >
              Buy am now
            </a>
          </div>
          <div
            class="col-md-6 d-none d-md-flex justify-content-end globe-wrap"
            data-aos="fade-in"
            data-aos-easing="ease"
            data-aos-duration="1000"
          >
            <img id="globe" src="@/assets/images/globe.gif" alt="" />
          </div>
        </div>
        <div class="header-items">
          <div class="header-item">
            <div class="count">1,000,000+</div>
            <div class="label">Products</div>
          </div>
          <div class="header-item">
            <div class="count">1,000+</div>
            <div class="label">Suppliers</div>
          </div>
          <div class="header-item">
            <div class="count">50,000+</div>
            <div class="label">Retailers</div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="isPopUpOpen" class="pop-up">
      <button class="products-close" @click="closePopUp">
        <i class="ri-close-line"></i>
      </button>
      <div class="overlay"></div>
      <!-- <img @click="navigateToWebsite" style="width: 100%; height: 100%; position: relative; object-fit: contain"
           src="/images/dollar.jpg"> -->
      <SubscribeForm :closePopUp="closePopUp" />
    </div>
    <button
      v-if="!isPopUpOpen"
      @click="openContactPopUp"
      class="call-btn animate-bounce"
    >
      <i style="font-size: 20px; color: #fff" class="fas fa-phone"></i>
    </button>
  </div>
  <div v-if="isContactPopUpOpen && !isPopUpOpen" class="contact-popout">
    <button class="products-close" @click="closeContactPopUp">
      <i class="ri-close-line"></i>
    </button>
    <div style="display: flex">
      <img style="width: 200px; margin: 0 auto" src="/images/call.png" />
    </div>
    <p style="line-height: 1.1" class="header-subtitle text-center">
      Request a call from us <br />today
    </p>
    <form @submit.prevent="submitForm">
      <div class="mt-2">
        <label class="text-[#3c4257]">Name</label>
        <input v-model="formData.name" name="username" type="text" required />
      </div>
      <div class="mt-2">
        <label class="text-[#3c4257]">Phone number</label>
        <input
          v-model="formData.phone"
          name="phoneNumber"
          type="text"
          pattern="(^0[789]\d{9}$)"
          required
        />
      </div>
      <button
        style="width: 100%; margin-bottom: 0.5rem"
        class="primary-btn cta"
        type="submit"
        :disabled="isLoading"
      >
        {{ isLoading ? "Submitting..." : "Call me" }}
      </button>
    </form>
  </div>
  <div v-if="toast" class="toastPop">{{ toastMessage }}</div>
</template>

<script>
import Typed from "typed.js";
import "particles.js/particles";
import SubscribeForm from "../global/SubscribeForm.vue";

export default {
  data() {
    return {
      isPopUpOpen: false,
      isContactPopUpOpen: false,
      formData: {
        name: "",
        phone: "",
      },
      toast: false,
      toastMessage: "",
      isLoading: false,
    };
  },
  components: { SubscribeForm },
  name: "Header",
  mounted() {
    this.initParticles();
    setTimeout(() => {
      this.isPopUpOpen = true;
      document.scrollingElement.style.overflow = "hidden"; // Prevent scrolling
    }, 2000);
  },
  methods: {
    initParticles() {
      window.particlesJS("particles", {
        particles: {
          number: { value: 80, density: { enable: true, value_area: 800 } },
          color: { value: "#ffffff" },
          shape: {
            type: "circle",
            stroke: { width: 0, color: "#000000" },
            polygon: { nb_sides: 5 },
            image: { src: "img/github.svg", width: 100, height: 100 },
          },
          opacity: {
            value: 0.2,
            random: false,
            anim: { enable: false, speed: 1, opacity_min: 0.1, sync: false },
          },
          size: {
            value: 3,
            random: true,
            anim: { enable: false, speed: 40, size_min: 0.1, sync: false },
          },
          line_linked: {
            enable: true,
            distance: 150,
            color: "#ffffff",
            opacity: 0.03,
            width: 1,
          },
          move: {
            enable: true,
            speed: 0.2,
            direction: "none",
            random: false,
            straight: false,
            out_mode: "out",
            bounce: false,
            attract: { enable: false, rotateX: 600, rotateY: 1200 },
          },
        },
        interactivity: {
          detect_on: "canvas",
          events: {
            onhover: { enable: false },
            onclick: { enable: false },
            resize: true,
          },
          modes: {
            grab: { distance: 400, line_linked: { opacity: 1 } },
            bubble: {
              distance: 400,
              size: 40,
              duration: 2,
              opacity: 8,
              speed: 3,
            },
            repulse: { distance: 200, duration: 0.4 },
            push: { particles_nb: 4 },
            remove: { particles_nb: 2 },
          },
        },
        retina_detect: true,
      });
    },
    initTyped() {
      const options = {
        strings: [
          "Split payment,",
          "Monthly rent,",
          "Weekly tuition,",
          "Monthly HMO,",
        ],
        typeSpeed: 100,
        backDelay: 2000,
        backSpeed: 100,
        loop: true,
      };
      const typed = new Typed(".typed", options);
      typed.start();
    },
    closePopUp() {
      this.isPopUpOpen = false;
      document.scrollingElement.style.overflow = "";
    },
    openContactPopUp() {
      this.isContactPopUpOpen = true;
      document.scrollingElement.style.overflow = "";
    },
    closeContactPopUp() {
      this.isContactPopUpOpen = false;
      document.scrollingElement.style.overflow = "";
    },
    navigateToWebsite() {
      const url = "https://bulk.clan.africa";
      window.open(url, "_blank");
    },
    async submitForm() {
      // Check if the inputs are empty
      if (!this.formData.name || !this.formData.phone) {
        this.showToast("Please fill in all fields.");
        return;
      }

      // Validate phone number
      if (!this.validatePhoneNumber()) {
        this.showToast("Please enter a valid phone number.");
        return;
      }

      // If all validations pass, submit the form
      try {
        this.isLoading = true;
        // Send form data to the endpoint
        const response = await fetch(
          // "https://ccendpoints.herokuapp.com/api/v2/m2m/create/customer-call-request",
          " https://lendnode.creditclan.com/mtom/api/create/customer-call-request ",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(this.formData),
          }
        );

        // Handle response as needed
        if (response.ok) {
          this.isLoading = false;
          this.showToast("Your request has been successfully submitted");
          setTimeout(() => {
            this.isContactPopUpOpen = false;
          }, 1000);
        } else {
          console.error("Failed to submit form");
        }
      } catch (error) {
        console.error("Error submitting form:", error);
      }
    },
    validatePhoneNumber() {
      const phoneNumberPattern = /^(0[789]\d{9})$/;
      return phoneNumberPattern.test(this.formData.phone);
    },
    showToast(message) {
      this.toastMessage = message;
      this.toast = true;
      setTimeout(() => {
        this.toast = false;
      }, 3000); // Hide toast after 3 seconds
    },
  },
};
</script>

<style scoped lang="scss">
.header {
  padding: 10rem 0 20rem;
  min-height: 100vh;
  z-index: 3;
  background: #0e1020;
  color: #eee;
  position: relative;
  width: 100%;
  background: linear-gradient(270deg, #0a142d, #080f23, #081544, #040f2c);
  background-size: 600% 600%;
  overflow: hidden;
  animation: AnimationName 15s ease infinite;

  @media (max-width: 1100px) {
    padding-bottom: 5rem;
  }

  .container {
    position: relative;
    z-index: 1000;
  }
}

@keyframes AnimationName {
  0% {
    background-position: 0 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0 50%;
  }
}

.header #globe {
  width: 90%;
  animation: globe-animation 5s ease-in-out alternate-reverse infinite;
  filter: brightness(0.8);
}

@keyframes globe-animation {
  0% {
    transform: translateY(0px);
  }

  50% {
    transform: translateY(-10px);
  }

  100% {
    transform: translateY(10px);
  }
}

.header .globe-text {
  position: absolute;
  top: 50%;
  left: 50%;
  padding: 1.5rem;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(2px);
  color: #0e1020;
  font-weight: 500;
  animation: globe-animation 4s ease-in-out alternate-reverse infinite;
}

.header .wrap {
  position: relative;
  z-index: 2;
  width: 100%;
  height: 100%;
}

.header .caption {
  color: #3864e7;
  font-size: 1rem;
  text-transform: uppercase;
  margin-bottom: 1.5rem;
}

.header-title {
  font-size: 4.4rem;
  font-weight: 900;
  font-family: "Nunito Sans", sans-serif !important;
  line-height: 4.8rem;

  .typed {
    font-family: "Nunito Sans", sans-serif !important;
  }

  @media (max-width: 1100px) {
    font-size: 3.3rem;
    line-height: 4rem;
  }
}

.header-subtitle {
  font-size: 1.2rem;
  line-height: 1.5;
  margin-top: 2rem;
  max-width: 500px;
}

.header-items {
  width: min-content;
  margin-top: 5rem;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem 5rem;
  z-index: 2;
  position: relative;

  @media (max-width: 1100px) {
    margin-top: 5rem;
    grid-template-columns: repeat(2, 1fr);
    gap: 3rem;
  }
}

.header-item .count {
  font-size: 2.2rem;
  font-weight: 800;
  line-height: 1;
  color: #e26641;
}

.header-item .label {
  margin-top: 10px;
  opacity: 0.7;
  font-size: 1rem;
  white-space: nowrap;
}

:deep(.typed-cursor) {
  color: #fb8627;
}

.primary-btn {
  background: darken(#fb8627, 10);
  color: #fff;

  &:hover {
    background: darken(#fb8627, 20);
  }
}

.overlay {
  position: fixed;
  inset: 0;
  background: #000;
  opacity: 0.85;
}

.pop-up {
  position: fixed;
  width: 100%;
  height: 100vh;
  inset: 0;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;

  .pop {
    position: relative;
    width: 70%;
    height: 70%;
    padding: 2rem;
    background: #ecf2f7;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 12px;

    .overlay {
      position: absolute;
      inset: 0;
      background: url("/images/pop-up.png");
      opacity: 0.1;
    }
  }

  h1 {
    font-size: 4rem;
    font-weight: 600;
    font-family: "Nunito Sans", sans-serif !important;
    line-height: 4.8rem;
    color: #000;
    text-align: center;
  }
}

.products-close {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  font-size: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-bottom: 2rem;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  position: absolute;
  top: 1rem;
  right: 1rem;
  z-index: 99;
  background: #c2334d;
  color: #fff;

  &:hover {
    background: none;
    border: 1px solid #c2334d;
    color: #c2334d;
  }
}

.blocked-text {
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke-color: #000;
  -webkit-background-clip: text;
  text-transform: uppercase;
  font-size: 8rem;
  line-height: 1.1;
  -webkit-text-stroke-width: 1px;
  background-image: url("/images/pop.png");
  background-size: 62%;
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  /* Optional shadow for better contrast */
}

.pacifico {
  font-family: "Pacifico", cursive;
}

.twisted {
  font-weight: 100;
  font-size: 3rem;
}

@media (max-width: 768px) {
  .blocked-text {
    font-size: 3.5rem;
    text-transform: none;
    line-height: 1.1;
    background-size: cover;
    padding-bottom: 1rem;
  }

  .twisted {
    font-size: 1.8rem;
    line-height: 1.1;
  }

  .pop-up {
    h1 {
      line-height: 0.8;
      font-size: 3.5rem;
    }
  }
}

.call-btn {
  position: fixed;
  bottom: 1rem;
  right: 2rem;
  height: 60px;
  width: 60px;
  border-radius: 50%;
  z-index: 9999;
  background: #3b82f6;
  display: flex;
  align-items: center;
  justify-content: center;
}

.animate-bounce {
  animation: bounce 2.5s infinite;
}

@keyframes bounce {
  0%,
  20%,
  100% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-20px);
  }

  25%,
  75% {
    animation-timing-function: steps(1, end);
  }

  25% {
    visibility: visible;
  }

  75% {
    visibility: hidden;
  }
}

.contact-popout {
  position: fixed;
  bottom: 1rem;
  right: 2rem;
  min-height: 500px;
  min-width: 350px;
  border-radius: 12px;
  z-index: 9999;
  background: #fff;
  padding: 1rem;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px -1px rgba(0, 0, 0, 0.1);

  input {
    width: 100%;
    border-radius: 2px;
    margin: 10px 0;
    outline: none;
    font-size: 15px;
    line-height: 20.49px;
    padding: 0.5rem 1rem;
    border: 1px solid lightgray;
  }

  @media (max-width: 768px) {
    bottom: 0;
    right: 0;
    min-height: 500px;
    min-width: 100%;
  }
}

.toastPop {
  position: fixed;
  background: #a1e8a1;
  padding: 20px;
  top: 5rem;
  right: 2rem;
  border-radius: 8px;
  z-index: 99;
}
</style>
