<template>
  <nav
    id="nav" class="navbar p-0"
    :class="{ scrolled: scrolled && direction === 'up', hide: scrolled && direction === 'down', dark: dark }"
  >
    <div class="container">
      <div class="nav-inner d-flex w-100">
        <a href="/" class="logo-container">
          <img v-show="scrolled || dark" :src="logo.dark" alt="" class="logo">
          <img v-show="!scrolled && !dark" :src="logo.light" alt="" class="logo">
        </a>
        <ul class="menu ms-auto mb-0">
          <li>
            <a href="https://bulk.clan.africa" target="_blank" class="menu-links">Store</a>
          </li>
          <li>
            <a href="https://shop.clan.africa" target="_blank" class="menu-links">Business</a>
          </li>
          <li>
            <a href="https://rent.clan.africa" target="_blank" class="menu-links">Individual</a>
          </li>
          <li>
            <a href="https://bulk.clan.africa" target="_blank" class="menu-links menu-link--bg">
              Get started
            </a>
          </li>
        </ul>
        <button @click="toggleMobileNav()" class="menu-icon">
          <i class="ri-menu-line"></i>
        </button>
      </div>
    </div>

    <div class="nav-mobile" :class="{open: isMobileNavVisible}">
      <ul class="menu-mobile">
        <li>
          <a href="https://shop.clan.africa" target="_blank" class="mobile-links">Merchants</a>
        </li>
        <li class="mobile-dropdown">
          <router-link to="/about" class="mobile-links">Why Clan</router-link>
          <div class="sub-links-two open">
            <router-link to="/about" class="sub-mobile-link">How we work</router-link>
            <router-link to="/contact" class="sub-mobile-link">Contact Us</router-link>
          </div>
        </li>
        <li>
          <a href="https://bulk.clan.africa" target="_blank" class="primary-btn cta">
            Get started
          </a>
        </li>
      </ul>
    </div>
  </nav>
</template>

<script>
export default {
  name: "Navbar",
  props: {
    dark: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      scrolled: false,
      direction: 'down',
      last: null,
      modals: {
        signUp: false,
        signIn: false
      },
      isMobileNavVisible: false,
      logo: {
        dark: '/images/clan.png',
        light: '/images/clan-light.png'
      }
    }
  },
  created() {
    const today = new Date();
    if (today.getMonth() === 11 && (today.getDate() > 20 || today.getDate() < 31)) {
      this.logo = {
        dark: '/images/clan-christmas-dark.png',
        light: '/images/clan-christmas-light.png',
      }
    }
  },
  mounted() {
    document.addEventListener('scroll', this.handleScroll);
  },
  methods: {
    handleScroll(e) {
      const scrollTop = e.target.scrollingElement.scrollTop;
      this.scrolled = scrollTop > 50;
      const st = window.pageYOffset || document.documentElement.scrollTop;
      if (st > this.last) {
        this.direction = 'down';
      } else {
        this.direction = 'up';
      }
      this.last = st <= 0 ? 0 : st;
    },
    toggleMobileNav() {
      this.isMobileNavVisible = !this.isMobileNavVisible;
    }
  }
}
</script>

<style scoped lang="scss">
.navbar {
  background: transparent;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  opacity: 1;
  -webkit-transition: all .3s linear;
  transition: all .3s linear;
  z-index: 1000;
}

.navbar.scrolled {
  background: #fff;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, .1);
  position: fixed;
  top: 0;
}

.nav-mobile {
  background: #fff;
  overflow: hidden;
  padding: 0;
  position: absolute;
  top: calc(100% + 10px);
  left: 50%;
  z-index: -1;
  transition: all .25s ease-in-out;
  width: 90%;
  margin: 0 auto;
  border-radius: 6px;
  opacity: 0;
  pointer-events: none;
  transform: translateX(-50%) translateY(-10px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, .1);
}

.nav-mobile.open {
  opacity: 1;
  pointer-events: auto;
  transform: translateX(-50%) translateY(0);
}

.menu-mobile {
  padding: 2rem;
}

.mobile-links {
  color: #00000099;
  text-decoration: none;
  font-weight: 500;
  letter-spacing: .03rem;
  font-size: 1.2rem;
  padding: 1.5rem 1.5rem;
  cursor: pointer;
  width: 100%;
  border-radius: 6px;

  &:hover {
    background: rgba(0, 0, 0, .1);
  }
}

button.mobile-links {
  margin: 1rem 1rem;
  padding: 1rem 2rem;
  display: inline-flex;
  background: #FB8627;
  color: #fff;
  transition: all .2s ease-in-out;

  &:hover {
    background: #c46417;
  }
}

.nav-mobile li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.navbar.scrolled .menu-links,
.navbar.dark .menu-links {
  color: #222;
}

.navbar.scrolled .caret path {
  fill: #314057;
}

.navbar.hide {
  top: -100px;
  opacity: 0;
}

.nav-inner {
  height: 90px;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.navbar .menu {
  height: 100%;
  display: flex;
  align-items: center;
  position: relative;
}

.menu-links {
  color: #ffffffd6;
  display: none;
  text-decoration: none;
  margin-left: 42px;
  font-weight: 400;
  font-size: 1rem;
  cursor: pointer;
}

.menu-links.active,
.menu-links:hover {
  color: #fff;
}

.menu-icon {
  display: inline-block;
  cursor: pointer;
  //width: 2.6rem;
  width: 40px;
  height: 40px;
  font-size: 1.3rem;
  border-radius: 6px;
  background: none;
  color: #fff;
  border: 1px solid #fff;
}

.navbar {

  &.scrolled .menu-icon,
  &.dark .menu-icon {
    background: none;
    color: #333;
    border: 1px solid #333;
  }
}

.menu-link--bg {
  box-shadow: 0 2px 2px rgba(0, 0, 0, .027);
  background: #FB8627;
  color: #fff !important;
  line-height: 0;
  padding: 1.2rem 2rem;
  transform: translateY(0px);
  transition: transform .5s linear;
  border-radius: 6px;
  cursor: pointer;
}

.menu-link--bg:hover {
  transform: translateY(-5px);
}

.logo-container {
  display: flex;
  height: 100%;
  align-items: center;
}

.logo {
  width: 85px;
}

.dropdown {
  background: white;
  box-shadow: 0 2px 5px rgba(0, 0, 0, .2);
  border-radius: 4px;
  display: inline-block;
  position: absolute;
  padding: .4rem 0;
  top: 140%;
  right: -50000px;
  z-index: -2;
  opacity: 0;
  -webkit-transition: top .4s ease-in-out, opacity .4s linear;
  transition: top .4s ease-in-out, opacity .4s linear;
  overflow: visible;
}

.dropdown a {
  display: block;
  width: 100%;
  padding: .5rem 1rem;
  color: #314057;
  text-decoration: none;
  font-weight: 300;
  white-space: nowrap;
  text-align: left;
  font-size: .9rem;
}

.dropdown a:hover {
  color: #FB8627;
}

.menu-links {
  position: relative;
}

.menu li:hover .dropdown {
  right: -10px;
  top: 80%;
  z-index: 4;
  opacity: 1;
}

.menu li {
  height: 100%;
  display: flex;
  align-items: center;
  position: relative;
}

.arrow-up {
  width: 0;
  height: 0;
  position: absolute;
  right: 32px;
  top: -7px;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 8px solid white;
}

@media only screen and (min-width: 1100px) {
  .menu-links {
    display: inline-flex;
    align-items: center;
  }

  .menu-icon {
    display: none;
  }
}

.announcement {
  background: #e5fcff;
  width: 100%;
  height: 55px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all .2s ease-in-out;

  .content {
    color: rgb(40, 40, 40);
  }
}

.navbar.scrolled .announcement {
  height: 0;
}
</style>
