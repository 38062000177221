<template>
  <section class="gallery-section">
    <div class="container">
      <h2 class="tc section-main-title big-title">
        We work hard, we play hard
      </h2>
      <p class="p tc">We stay on top of our industry by being experts in yours.</p>
      <br>
      <br>
      <br>

    </div>
    <div class="gallery">
      <swiper
          loop
          :autoplay="{ delay: 0, disableOnInteraction: false }"
          :breakpoints="{ 470: { slidesPerView: 2, spaceBetween: 20 }, 760: { slidesPerView: 3, spaceBetween: 20 }, 1100: { slidesPerView: 4,spaceBetween: 20 }}"
          :space-between="30"
          :speed="6000"
      >
        <swiper-slide>
          <img class="gallery-image" src="/images/cc-4.jpg" alt="">
        </swiper-slide>
        <swiper-slide>
          <img class="gallery-image" src="/images/cc-6.jpg" alt="">
        </swiper-slide>
        <swiper-slide>
          <img class="gallery-image" src="/images/cc-5.jpg" alt="">
        </swiper-slide>
        <swiper-slide>
          <img class="gallery-image" src="/images/so.jpg" alt="">
        </swiper-slide>
        <swiper-slide>
          <img class="gallery-image" src="/images/cc-7.jpg" alt="">
        </swiper-slide>
        <swiper-slide>
          <img class="gallery-image" src="/images/sofe.jpg" alt="">
        </swiper-slide>
      </swiper>
    </div>
  </section>
</template>

<script>
import { Swiper, SwiperSlide } from 'swiper/vue';
import SwiperCore, { Autoplay } from 'swiper';
import 'swiper/swiper-bundle.min.css';

SwiperCore.use([Autoplay]);

export default {
  name: "Gallery",
  components: { Swiper, SwiperSlide }
}
</script>

<style scoped lang="scss">
.gallery-section {
  padding: 20rem 0 6rem;
  background: #fff;
  clip-path: url(#wave-high-right);
  margin-top: -300px;
  position: relative;
  z-index: 10;
  overflow: hidden;
  width: 100vw;

  @media (max-width: 1100px) {
    padding: 6rem 0;
    clip-path: none;
    margin: 0;
  }
}


.gallery-image {
  width: 100%;
  border-radius: 6px;
}
</style>
