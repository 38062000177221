<template>
    <div class="bg-custom rs-about about-style1 pt-160 pb-160 md-pt-80 md-pb-75 position-relative">
        <div class="container">
            <div class="row y-middle">
                <div class="pl-30 md-pl-15">
                    <div class="sec-title">
                        <h2 class="section-main-title text-center text-white mx-auto"
                            style="font-size: 3rem;">
                            This is just to let you know <br/>
                            We're now Clan, but you can call us family.
                        </h2>

                    </div>
                </div>
            </div>
        </div>
        <div class="about-animation">
            <div class="animate one">
                <img src="/images/dot1.png" alt="About">
            </div>
            <div class="animate two">
                <img src="/images/dot2.png" alt="About">
            </div>
            <div class="animate three">
                <img src="/images/dot3.png" alt="About">
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Strip',
}
</script>

<style lang="scss" scoped>
.bg-custom {
    background: linear-gradient(rgb(49 31 60 / 60%), rgb(78 57 96 / 60%)), url('/images/unity.jpg');
    background-size: cover;
    background-attachment: fixed;
    background-position-y: 50%;
}

.pt-160 {
    padding-top: 160px;
}

.pb-160 {
    padding-bottom: 160px;
}

.rs-about.about-style1 .about-animation .animate img {
    max-width: 20px;
}

.rs-about.about-style1 .about-animation .animate {
    position: absolute;
    bottom: 0;
}

.rs-about.about-style1 .about-animation .animate.one {
    left: 45%;
    top: 17%;
}

.rs-about.about-style1 .about-animation .animate.two {
    right: 14%;
    top: 10%;
}

.rs-about.about-style1 .about-animation .animate.three {
    left: 47%;
    bottom: 20%;
}
</style>