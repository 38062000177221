<template>
  <section class="key-focus">
    <div class="container" data-aos="fade-up" data-aos-easing="ease" data-aos-duration="1000">
      <div class="product">
        <div class="product-content text-animate-two">
          <h2 class="section-title">
            Key Focus
          </h2>
          <h2 class="section-main-title">
            Empowering product import across the globe
          </h2>
          <p class="p">
            We believe BNPL will only work in Africa if the right ecosystems are built. We are that team to do it.
          </p>
          <a href="https://bulk.clan.africa/" target="_blank" class="primary-btn">
            Get started
          </a>
        </div>

        <div class="product-card-container">
          <a href="#" class="product-card orange">
            <div class="prod-bg" role="presentation"></div>
            <div class="product-avatar">
              <img src="@/assets/images/flags/ng.svg" alt=""/>
            </div>
            <h3 class="product-card-title">
              For Nigerians
            </h3>
            <p class="content switch-text-btn">
              <span>Simplified pay-later services now available in Nigeria</span>
              <a href="/about">Learn more</a>
            </p>
          </a>
          <a href="#" class="product-card green">
            <div class="prod-bg" role="presentation"></div>
            <div class="product-avatar">
              <img src="@/assets/images/flags/gh.svg" alt=""/>
            </div>
            <h3 class="product-card-title">
              For Ghanaians
            </h3>
            <p class="content">
              <span>Coming soon in Ghana</span>
            </p>
          </a>
          <a href="#" class="product-card blue">
            <div class="prod-bg" role="presentation"></div>
            <div class="product-avatar">
              <img src="@/assets/images/flags/ci.svg" alt=""/>
            </div>
            <h3 class="product-card-title">
              For Ivorians
            </h3>
            <p class="content">
              <span>Coming soon in Côte d'Ivoire</span>
            </p>
          </a>
          <div class="product-card pink">
            <div class="prod-bg" role="presentation"></div>
            <div class="product-avatar">
              <img src="@/assets/images/flags/lr.svg" alt=""/>
            </div>
            <h3 class="product-card-title">
              For Liberians
            </h3>
            <p class="content">
              <span>Coming soon in Liberia</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "KeyFocus",
}
</script>

<style scoped lang="scss">
.key-focus {
  padding: 20rem 0 12rem;
  position: relative;
  background: #ecf2f7;
  clip-path: url(#wave-high-left-short);
  margin-top: -190px;
  z-index: 10;
  overflow: hidden;

  @media (max-width: 1100px) {
    clip-path: none;
    margin: 0;
    padding: 6rem 0;
  }
}

.key-focus .container {
  position: relative;
  z-index: 2;
}

.product {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  flex-direction: column;
}

.product-content {
  width: 100%;
  padding-bottom: 52px;
}

.product-title {
  font-size: 1.5rem;
  line-height: 1.3;
  font-weight: 600;
}

.product-card-container {
  width: 100%;
  display: flex;
  position: relative;
  justify-content: space-between;
  align-items: stretch;
  flex-wrap: wrap;
}

.prod-bg {
  width: 30%;
  height: 70px;
  background: #0073B1;
  right: -32%;
  border-radius: 0 0 0 40px;
  top: 0;
  position: absolute;
  transition: right .3s linear;
}

@media only screen and (min-width: 940px) {
  .product {
    flex-direction: row;
  }
  .product-content {
    width: 35%;
    padding-bottom: 0;
  }
  .product-card-container {
    width: 53%;
  }
}

.product-card {
  width: 100%;
  background: #F8FAFC;
  padding: 40px 30px;
  border-radius: 12px;
  overflow: hidden;
  border: 1px solid rgba(0, 0, 0, .05);
  margin: 1rem 0;
  position: relative;
  text-decoration: none;

  &.blue {
    background: #e0f0ff;

    .product-svg path,
    .product-avatar .product-svg rect,
    .product-avatar .product-svg polygon {
      fill: #0073B1;
      transition: fill .5s linear;
    }
  }

  &.orange {
    background: #ffeee0;

    .product-svg path,
    .product-avatar .product-svg rect,
    .product-avatar .product-svg polygon {
      fill: #FB8627;
      transition: fill .5s linear;
    }
  }

  &.green {
    background: #e0ffea;

    .product-svg path,
    .product-avatar .product-svg rect,
    .product-avatar .product-svg polygon {
      fill: #67cb96;
      transition: fill .5s linear;
    }
  }

  &.red {
    background: #ffe0e0;

    .product-svg path,
    .product-avatar .product-svg rect,
    .product-avatar .product-svg polygon {
      fill: #c94343;
      transition: fill .5s linear;
    }
  }

  &.pink {
    background: #ffe0f0;

    .product-svg path,
    .product-avatar .product-svg rect,
    .product-avatar .product-svg polygon {
      fill: #b23e87;
      transition: fill .5s linear;
    }
  }

  &.teal {
    background: #e0f8ff;

    .product-svg path,
    .product-avatar .product-svg rect,
    .product-avatar .product-svg polygon {
      fill: #3587c2;
      transition: fill .5s linear;
    }
  }
}

@media only screen and (min-width: 640px) {
  .product-card {
    width: 48%;
  }
}

.product-avatar {
  width: 60px;
  margin-bottom: 40px;
  transition: all .3s cubic-bezier(0.6, -0.28, 0.735, 0.045);

  img {
    display: block;
    border-radius: 10px;
    box-shadow: 0 0 16px rgba(60, 55, 241, 0.09), 0 4px 4px rgba(0, 0, 0, 0.03);
  }
}

.product-card:hover .product-avatar .product-svg path,
.product-card.active .product-avatar .product-svg path,
.product-card:hover .product-avatar .product-svg circle,
.product-card.active .product-avatar .product-svg circle,
.product-card:hover .product-avatar .product-svg rect,
.product-card.active .product-avatar .product-svg rect,
.product-card:hover .product-avatar .product-svg polygon,
.product-card.active .product-avatar .product-svg polygon {
  fill: #ffffff !important;
}

.product-card-title {
  font-weight: 700;
  margin-bottom: .7rem;
  line-height: 1.6;
  color: #1d2e47;
  font-size: 1.2rem;
}

.product-card p {
  line-height: 1.6;
  font-weight: 300;
  color: #314057;
  font-size: 1rem;
  opacity: .8;
}

.product-card {
  .content {
    position: relative;
    padding: 2rem 1rem;

    span {
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      transform: translateY(0);
      opacity: 1;
      transition: all .15s ease-in-out;
    }

    button, a {
      display: flex;
      position: absolute;
      top: 0;
      left: 0;
      padding: .2rem 1rem;
      background: none;
      border: 1px solid rgba(0, 0, 0, .6);
      color: #0E1020;
      border-radius: 4px;
      transform: translateY(10px);
      opacity: 0;
      cursor: pointer;
      transition: all .15s ease-in-out;

      &:hover {
        background: #0E1020;
        color: #fff;
      }
    }
  }

  &:hover {
    .switch-text-btn {
      span {
        transform: translateY(-10px);
        opacity: 0;
      }

      button, a {
        transform: translateY(0);
        opacity: 1;
      }
    }
  }
}

.circle {
  position: absolute;
  top: 300px;
  left: 400px;
  opacity: .4;
  /* filter: hue-rotate(10deg) */
}

.circle img {
  width: 150px;
  animation: spin 10s linear infinite;
}

.circle__two {
  position: absolute;
  top: 950px;
  left: 500px;
  opacity: .5;
}

.circle__three {
  position: absolute;
  top: 200px;
  right: 300px;
  opacity: .5;
  filter: hue-rotate(320deg);
  animation: spin 20s linear alternate-reverse infinite;
}

.circle__three img {
  width: 80px;
}

.circle__four {
  position: absolute;
  top: 300px;
  left: 50px;
  opacity: .5;
  filter: hue-rotate(320deg);
  animation: spin 20s linear alternate-reverse infinite;
}

.circle__four img {
  width: 80px;
  filter: hue-rotate(180deg);
}

.circle__two img {
  filter: hue-rotate(180deg);
  animation: spin 20s linear alternate-reverse infinite;
  width: 120px;
}

.triangle {
  position: absolute;
  left: 35px;
  bottom: 90px;
  opacity: .5;
}

.triangle img {
  filter: hue-rotate(320deg);
  width: 90px;
  animation: spin 4s ease-in-out infinite;
}

.stairs {
  position: absolute;
  top: 100px;
  right: 20px;
}

.stairs img {
  filter: hue-rotate(180deg);
  width: 100px;
}

</style>
