<template>
  <section class="p-about-section">
    <div class="container">
      <div class="about-us">
        <div class="row align-items-center">
          <div class="col-md-5 pe-md-4">
            <img src="/images/woman.jpg" alt="" class="w-100 rounded-3">
          </div>
          <div class="col-md-7 ps-md-4 mt-5 mt-md-0">
            <h3 class="section-title">Our Story</h3>
            <h2 class="section-main-title">A great vision, a relentless team</h2>
            <div class="tab-section active">
              <p class="p">
                With a strong belief that Africa will only fulfill her objective when the true potentials of
                borderless credit are fulfilled, We started building the tech and later the business that followed.
              </p>
              <p class="p">
                It has been an incredible journey, driven by a team of engineers, finance experts and customer addicts
                who certainly will not be resting until every African has access to affordable credit
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "OurStory"
}
</script>

<style scoped lang="scss">
.p-about-section {
  padding: 22rem 0 12rem;
  clip-path: url(#wave-high-right);
  margin-top: -290px;
  z-index: 10;
  position: relative;
  background: #fff;
  overflow: hidden;

  @media (max-width: 1100px) {
    padding: 6rem 0;
    clip-path: none;
    margin: 0;
  }
}

.about-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.about-content-one,
.about-content-two {
  position: relative;
}

.about-content-one {
  padding-left: 0;
  width: 100%;
  -webkit-box-ordinal-group: 0;
  -ms-flex-order: -1;
  order: -1;
}

.about-content-two {
  width: 100%;
  position: relative;
  padding-right: 0;
}

.about-list li {
  font-size: 1rem;
  line-height: 1.3rem;
  padding-bottom: 2rem;
  color: rgba(0, 0, 0, .7);
  font-weight: 300;
  position: relative;
  padding-left: 2.5em
}

.about-list li::before {
  content: "";
  height: 16px;
  width: 16px;
  display: inline-block;
  position: absolute;
  left: 0;
  top: 0;
  margin-right: 22px;
  vertical-align: text-top;
  background: url(/images/tick.png) no-repeat center;
  background-size: contain;
  padding-top: 5px;
}

.about-content-one p,
.about-one-content-one p,
.flex-section p {
  line-height: normal;
  padding-bottom: 1.5rem;
  color: rgba(0, 0, 0, .7);
  font-size: 1rem;
  font-weight: 300;
}

.about-content-two img,
.about-two-content-two img {
  width: 100%;
  border-radius: 12px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, .10);
  margin-top: 50px;
}

.about-two-content-two img {
  box-shadow: none;
  width: 100%;
}

</style>
