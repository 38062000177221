<template>
  <div>
    <Navbar dark/>
    <PageHeader
        title="We are easy to find"
        subtitle="We are here to help and answer any question you might have. We look forward to hearing from you."
    />
    <ContactForm/>
    <JoinUs/>
    <Footer/>
  </div>
</template>

<script>
import PageHeader from "@/components/global/PageHeader";
import Navbar from "@/components/global/Navbar";
import ContactForm from "@/components/contact/ContactForm";
import JoinUs from "@/components/home/JoinUs";
import Footer from "@/components/home/Footer";

export default {
  name: "Contact",
  components: { Footer, JoinUs, ContactForm, Navbar, PageHeader }
}
</script>
