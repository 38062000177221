<template>
  <div class="p-abt lending">
    <Navbar dark/>
    <PageHeader
        title="Solving your business problems is our priority"
        subtitle="We deliver intuitive and innovative technological solutions to your business in no time."
    />
    <OurStory/>
    <WhyChooseUs/>
    <Gallery/>
    <Footer/>
  </div>
</template>

<script>
import OurStory from "@/components/about/OurStory";
import Navbar from "@/components/global/Navbar";
import Footer from "@/components/home/Footer";
import WhyChooseUs from "@/components/about/WhyChooseUs";
import Gallery from "@/components/about/Gallery";
import PageHeader from "@/components/global/PageHeader";

export default {
  components: { PageHeader, Gallery, WhyChooseUs, Footer, Navbar, OurStory }
}
</script>
