const categories = [
  {
    id: 4,
    name: 'Phone & Tablets',
    image: '/images/phone-tablets.jpg',
    keyword: 'phone, tablet, samsung, iphone, ios, android',
  },
  {
    id: 5,
    name: 'Computing',
    image: '/images/computing.jpg',
    keyword: 'computer, laptop, desktop, windows, macbook',
  },
  {
    id: 6,
    name: 'Electronics',
    image: '/images/electronics.jpg',
    keyword: 'electric, standing fan, speaker',
  },
  {
    id: 7,
    name: 'Fashion',
    image: '/images/fashion.jpg',
    keyword: 'fashion, cloth, shirt, shoes, bag',
  },
  {
    id: 3,
    name: 'Home & Office',
    image: '/images/home-office.jpg',
    keyword: 'home, office',
  },
  {
    id: 1,
    name: 'Supermarket',
    image: '/images/supermarket.jpg',
    keyword: 'grocery',
  },
];

export default categories;
