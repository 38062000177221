<template>
  <div class="card p-4 rounded-4">
    <!-- Content of the paper/card -->
    <slot></slot>
  </div>
</template>

<script>
export default {
  // Your component logic
};
</script>

<style scoped>
/* Style for the paper/card */
.card {
  border: 1px solid #ddd; /* Add a border to the card */
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Add a subtle shadow effect */
}
</style>
