<template>
  <header class="page-header" id="particles">
    <div class="container">
      <div class="header-content">
        <div class="header-text">
          <div>
            <h1>{{ title }}</h1>
            <p v-if="subtitle">{{ subtitle }}</p>
            <br>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  name: "PageHeader",
  props: {
    title: {
      type: String, required: true
    },
    subtitle: {
      type: String
    }
  },
  mounted() {
    this.initParticles();
  },
  methods: {
    initParticles() {
      window.particlesJS("particles", {
        particles: {
          number: { value: 20, density: { enable: true, value_area: 800 } },
          color: { value: "#5ba9d9" },
          shape: {
            type: "circle",
            stroke: { width: 0, color: "#000000" },
            polygon: { nb_sides: 5 },
            image: { src: "img/github.svg", width: 100, height: 100 }
          },
          opacity: {
            value: 0.3,
            random: false,
            anim: { enable: false, speed: 1, opacity_min: 0.1, sync: false }
          },
          size: {
            value: 20,
            random: true,
            anim: { enable: false, speed: 40, size_min: 0.1, sync: false }
          },
          line_linked: {
            enable: true,
            distance: 150,
            color: "#ffffff",
            opacity: 0.03,
            width: 1
          },
          move: {
            enable: true,
            speed: 1,
            direction: "none",
            random: false,
            straight: false,
            out_mode: "out",
            bounce: false,
            attract: { enable: false, rotateX: 600, rotateY: 1200 }
          }
        },
        interactivity: {
          detect_on: "canvas",
          events: {
            onhover: { enable: false },
            onclick: { enable: false },
            resize: true
          },
          modes: {
            grab: { distance: 400, line_linked: { opacity: 1 } },
            bubble: { distance: 400, size: 40, duration: 2, opacity: 8, speed: 3 },
            repulse: { distance: 200, duration: 0.4 },
            push: { particles_nb: 4 },
            remove: { particles_nb: 2 }
          }
        },
        retina_detect: true
      });
    }
  }
}
</script>

<style scoped lang="scss">
.page-header {
  position: relative;
  padding: 8rem 0 12rem;
  background: #ecf6ff;
  color: white;
  text-align: center;
  overflow: hidden;
  z-index: 1;

  @media (max-width: 1100px) {
    padding: 8rem 0;
    clip-path: none;
    margin: 0;
  }
}

.page-header::before {
  content: '';
  position: absolute;
  background-image: url(/images/line.png);
  background-position: center;
  background-size: cover;
  width: 100%;
  height: 100%;
  bottom: -35px;
  left: 0;
  opacity: 0.1;
  z-index: -1;
}

.header-content {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 5rem 0;
  width: 100%;
  flex-wrap: wrap;
}

.header-text {
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;
  max-width: 650px;
  text-align: center;
}

.header-text h1 {
  color: rgb(1, 27, 41);
  position: relative;
  font-size: 3.4rem;
  font-weight: 800;
  line-height: 1.2;
}

.header-text p {
  color: rgb(1, 27, 41);
  font-weight: 300;
  margin-top: 1.5rem;
  font-size: 1rem;
  line-height: 1.6;
}
</style>
