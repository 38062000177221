<template>
  <div>
    <NavbarBlog dark/>
    <AnnouncementDetails/>
    <Strip/>
    <Footer/>
  </div>
</template>

<script>
import AnnouncementDetails from '../components/announcement/AnnouncementDetails.vue';
import Strip from '../components/announcement/Strip.vue';
import Footer from '../components/home/Footer.vue';
import NavbarBlog from '../components/global/NavbarBlog.vue';
export default {
    components: { AnnouncementDetails, Strip, Footer, NavbarBlog }
}
</script>

<style>

</style>