<template>
  <section class="p-about-section bg-light-blue">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-md-6 pe-md-4">
          <h3 class="section-title">
            Why Choose us?
          </h3>
          <h2 class="section-main-title">
            We offer peerless insight into solutions around credit, credibility
            and collection.
          </h2>
          <div class="row mt-4">
            <div class="col-md-6 ecosystem-lending-item">
              <img src="/images/verified.svg" alt="" />
              <p class="mt-3">
                <span>Security</span> conciousness is our business oxygen.
              </p>
            </div>
            <div class="col-md-6 ecosystem-lending-item">
              <img src="/images/together.svg" alt="" />
              <p class="mt-3">
                <span>Collaboration</span> with other fintechs for aggregation
                has been key.
              </p>
            </div>
            <div class="col-md-6 ecosystem-lending-item">
              <img src="/images/clock.svg" alt="" />
              <p class="mt-3">
                <span>Speed</span> to market is considered our premium quality.
              </p>
            </div>
            <div class="col-md-6 ecosystem-lending-item">
              <img src="/images/supports.svg" alt="" />
              <p class="mt-3">
                <span>Support</span> for our customers and partners is
                guaranteed 24/7.
              </p>
            </div>
          </div>
          <br />
          <router-link to="/contact" class="primary-btn">
            Find out more
          </router-link>
        </div>
        <div class="col-md-6 ps-md-4 mt-5 mt-md-0">
          <img src="/images/woman-2.jpg" class="w-100 rounded-3" alt="" />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'WhyChooseUs',
}
</script>

<style scoped lang="scss">
.p-about-section {
  position: relative;
  padding: 14rem 0 24rem;
  clip-path: polygon(0 7%, 100% 0, 100% 100%, 0 100%);
  margin-top: -90px;
  z-index: 10;
  overflow: hidden;

  @media (max-width: 1100px) {
    padding: 6rem 0;
    clip-path: none;
    margin: 0;
  }
}

.ecosystem-lending {
  padding: 0 0 6rem;
}

.ecosystem-lending-item {
  width: 47%;
}

.ecosystem-lending-item img {
  height: 40px;
  width: 40px;
  margin-top: 20px;
  margin-bottom: 6px;
}

.ecosystem-lending-item span {
  font-weight: 800;
}

.ecosystem-lending-item p {
  padding: 0 !important;
  font-size: 1rem;
  opacity: 0.8;
  line-height: 1.3rem;
}
</style>
