<template>
  <div>
    <NavbarBlog dark />
    <div class="container my-5 py-5 ">
      
      
        <!-- <router-link to="/careers" class="back-link">Back</router-link> -->
        <JobDetails
          title="Sales Executives"
          date="23/08/2023"
          content="Lorem ipsum dolor sit amet consectetur, adipisicing elit. Doloribus culpa assumenda soluta commodi optio eos. Illo laudantium iusto magnam consequatur?"
          buttonLabel="Apply"
        />
      </div>
      
    <Footer />
  </div>
</template>
<script>
// import JobDetails from "./JobDetails";
import JobDetails from '@/components/careers/JobDetails.vue'
import NavbarBlog from '@/components/global/NavbarBlog.vue'
import Footer from '@/components/home/Footer.vue'
export default {
  components: {
    JobDetails,
    NavbarBlog,
    Footer,
  },

  data() {
    return {
      drawerVisible: false,
    }
  },
}
</script>
<style>
.back-link {
  color: #333; /* Change the color as needed */
  text-decoration: none;
  border-bottom: 1px solid #333; /* Add a subtle underline effect */
  padding: 0.2rem 0.5rem; /* Adjust padding as needed */
  transition: border-color 0.3s ease; /* Add transition effect */
}

/* Apply the underline effect on hover */
.back-link:hover {
  border-color: transparent; /* Hide the underline on hover */
}


</style>
