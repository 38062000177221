<template>
  <section class="section-contact">
    <div class="circle">
      <img src="/images/circle.png" alt="">
    </div>
    <div class="circle__two">
      <img src="/images/circle.png" alt="">
    </div>
    <div class="triangle">
      <img src="/images/triangle.png" alt="">
    </div>
    <div class="stairs">
      <img src="/images/stairs.png" alt="">
    </div>
    <img src="/images/Subtract.png" class="subx" alt="">
    <div class="container">
      <h3 class="section-title">
        Let's do something amazing
      </h3>
      <h2 class="section-main-title">
        Just say Hello
      </h2>
      <br>
      <br>
      <div class="contact-main">
        <div class="contact-main-item">
          <p class="p cmi-first">
            Always feel free to contact us, We are open to discussing new projects, creative ideas or opportuinities to
            be part of your visions.
          </p>
          <div class="contact-info">
            <div>
              <h5>Address</h5>
              <p class="p">
                77, Samuel Adedoyin street,<br/> Victoria Island, Lagos.
              </p>
            </div>
            <div>
              <h5>Phone</h5>
              <a href="tel:+2349155577731" class="p">+2349155577731</a>
            </div>
            <div>
              <h5>Email</h5>
              <a href="mailto:support@clan.africa" class="p">support@clan.africa</a>
            </div>
            <div class="social">
              <a href="https://www.facebook.com/clanafricahq" target="_blank" class="social-wrapper facebook">
                <img src="/images/facebook.svg" alt=""/>
              </a>
              <a href="https://instagram.com/clanafricahq" target="_blank" class="social-wrapper instagram">
                <img src="/images/instagram.svg" alt=""/>
              </a>
              <a href="https://www.linkedin.com/company/clanafrica/" target="_blank" class="social-wrapper linkedin">
                <img src="/images/linkedin.svg" alt=""/>
              </a>
              <a href="https://twitter.com/clanafricahq" class="social-wrapper twitter">
                <img src="/images/twitter.svg" alt=""/>
              </a>
            </div>
          </div>
        </div>
        <div class="contact-main-item">
          <p class="p cmi-second">
            Always feel free to contact us, We are open to discussing new projects, creative ideas or opportuinities to
            be part of your visions.
          </p>
          <form action="">
            <input type="text" name="" required placeholder="Your Name" class="contact-input">
            <input type="text" name="" required class="contact-input" placeholder="Your Email">
            <select name="" class="contact-input" id="">
              <option value="">General Request</option>
              <option value="">Special Request</option>
              <option value="">Support</option>
            </select>
            <textarea name="" required placeholder="Your message" cols="20" rows="5" class="contact-input"></textarea>
            <button type="submit" class="primary-btn">
              Send
            </button>
          </form>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "ContactForm"
}
</script>

<style scoped lang="scss">
.section-contact {
  padding: 22rem 0 8rem;
  clip-path: url(#wave-high-right);
  margin-top: -290px;
  z-index: 10;
  position: relative;
  background: #fff;
  overflow: hidden;

  @media (max-width: 1100px) {
    padding: 6rem 0;
    clip-path: none;
    margin: 0;
  }
}


.contact-main {
  display: flex;
  justify-content: space-between;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  flex-wrap: wrap;
}

select {
  color: #314057;
}

.contact-main-item {
  width: 100%;
}

.contact-main-item:nth-child(2) {
  -webkit-box-ordinal-group: 0;
  -ms-flex-order: -1;
  order: -1;
  margin-bottom: 2rem;
}

.cmi-first {
  display: none;
}

p.cmi-second {
  display: block;
}

@media only screen and (min-width: 720px) {
  .contact-main-item {
    width: 50%;
    position: relative;
    padding: 0 2% 0 0;
  }

  .contact-main-item:nth-child(2) {
    width: 50%;
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
    padding: 0 0 0 2%;
  }

  .contact-main-item:first-child::after {
    content: "";
    background: #eee;
    width: 1px;
    height: 90%;
    position: absolute;
    right: 0;
    top: 0;
  }

  p.cmi-second {
    display: none;
  }

  p.cmi-first {
    display: block;
  }
}

.contact-svg {
  width: 80%;
}

.main-contact-input {
  width: 80%;
  padding: 22px 27px;
  border-radius: 5px;
  border: none;
  background: #F8F5F5;
  margin-bottom: 34px;
}

.main-contact-input:focus {
  outline: none;
}

.main-contact-input::-webkit-input-placeholder {
  color: #aaaaaa;
  font-size: 1rem;
  font-weight: 300;
  font-style: italic;
  font-family: 'poppins', sans-serif;
}

.main-contact-input::-moz-placeholder {
  color: #aaaaaa;
  font-size: 1rem;
  font-weight: 300;
  font-style: italic;
  font-family: 'poppins', sans-serif;
}

.main-contact-input:-ms-input-placeholder {
  color: #aaaaaa;
  font-size: 1rem;
  font-weight: 300;
  font-style: italic;
  font-family: 'poppins', sans-serif;
}

.main-contact-input::placeholder {
  color: #aaaaaa;
  font-size: 1rem;
  font-weight: 300;
  font-style: italic;
  font-family: 'poppins', sans-serif;
}

.h-contact .header-img img {
  width: 60%;
}

.h-contact .header-img {
  text-align: center;
  padding-top: 70px;
}

.contact-info {
  padding-top: 38px;
}

.contact-info div {
  padding-bottom: 15px;
}

.contact-info div h5 {
  padding-bottom: 7px;
}

.footer-copy-contact {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #ccc;
  padding: 2.8rem 0;
  flex-wrap: wrap;
  color: #314057;
}

.footer-contact-links a {
  color: #314057;
  font-weight: 400;
  font-size: .9rem;
  display: inline-block;
  margin-left: 16px;
  text-decoration: none;
}

.footer-contact-links a:hover {
  color: #3F75FC;
}

.subx {
  position: absolute;
  right: 0;
}

@media only screen and (max-width: 780px) {
  .footer-contact-links {
    margin: 0 auto;
  }

  .footer-contact-links a {
    line-height: 1.5;
    margin: 0 8px 1rem;
  }

  .footer-copy-div {
    text-align: center;
    line-height: 1.7;
    width: 100%;
    padding-bottom: 32px;
  }
}

.circle {
  position: absolute;
  top: 300px;
  left: 800px;
  opacity: .4;
  /* filter: hue-rotate(10deg) */
}

.circle img {
  width: 100px;
  animation: spin 10s linear infinite;
}

.circle__two {
  position: absolute;
  top: 900px;
  right: 100px;
  opacity: .5;
}

.circle__two img {
  filter: hue-rotate(180deg);
  animation: spin 20s linear alternate-reverse infinite;
  width: 100px;
}

.triangle {
  position: absolute;
  left: 35px;
  bottom: 20px;
  opacity: .5;
}

.triangle img {
  filter: hue-rotate(320deg);
  width: 80px;
  animation: spin 4s ease-in-out infinite;
}

.stairs {
  position: absolute;
  top: 100px;
  right: 20px;
}

.stairs img {
  filter: hue-rotate(180deg);
  width: 100px;
}
</style>
