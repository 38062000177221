<template>
  <div class="app-wrap" :class="{ 'show-side': !!sideView }">
    <div class="main-content">
      <router-view/>
    </div>
    <div class="side-content">
      <ProductsSignUp :visible="sideView === 'sign-up'" @close="sideView = ''"/>
    </div>
  </div>
  <Curves/>
</template>

<script>
import Curves from "@/components/global/Curves";
import ProductsSignUp from "@/components/global/ProductsSignUp";

export default {
  name: 'App',
  components: { ProductsSignUp, Curves },
  data() {
    return {
      sideView: '',
    }
  },
  mounted() {
    this.$emitter.on('sign-up', () => {
      this.sideView = 'sign-up';
    });
    this.$emitter.on('sign-in', () => {
      this.sideView = 'sign-in';
    });
  }
}
</script>

<style lang="scss" scoped>
.app-wrap {
  overflow: hidden;
  width: 100%;
  display: flex;

  .side-content {
    position: fixed;
    top: 0;
    right: 0;
    height: 100vh;
    width: 400px;
    background: #fff;
    box-shadow: -10px 0 10px -6px rgba(0, 0, 0, .08);
    transform: translateX(100%);
    z-index: 1000;
    transition: all .3s ease-in-out;
  }

  .main-content {
    width: 100%;
    transition: all .3s ease-in-out;
  }

  &.show-side {
    .main-content {
      transform: translateX(-380px);
    }

    .side-content {
      transform: translateX(0);
    }
  }
}
</style>
