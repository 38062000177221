<template>
  <div class="container contact-container">
    <div class="col w-100 img-container h-100 d-none d-md-flex">
      <img class="notebook" src="/images/grocery.jpg" />
    </div>
    <form
      @submit.prevent="submitForm"
      class="form-container bg-white col p-5 w-full h-full text-bg-light w-100 d-flex flex-column justify-content-center align-items-start"
    >
      <h1 class="w-100 text-center text-secondary">Restock for Free!</h1>
      <p class="gray-text text-center lh-base">
        We will supply you goods while you pay small small as you sell. No long
        process. Sign-up to learn more.
      </p>
      <div class="my-3 w-100">
        <label class="form-label text-secondary">Name</label>
        <input
          v-model="formData.name"
          name="name"
          required
          type="text"
          class="form-control"
        />
      </div>

      <div class="w-100">
        <label class="form-label text-secondary">Phone Number</label>
        <input
          v-model="formData.phone"
          type="tel"
          name="phone_number"
          class="form-control"
          pattern="(^0[789]\d{9}$)"
        />
      </div>
      <button type="submit" class="mt-4 submit-button fw-semibold fs-6">
        {{ isLoading ? "Please wait..." : "Sign Up" }}
      </button>
    </form>
  </div>
  <div v-if="toast" class="toastPop">{{ toastMessage }}</div>
</template>

<script>
export default {
  props: {
    closePopUp: {
      type: Function,
      required: false,
    },
  },
  data() {
    return {
      formData: {
        name: "",
        phone: "",
      },
      toast: false,
      toastMessage: "",
      isLoading: false,
    };
  },
  name: "SubscribeForm",
  methods: {
    async submitForm() {
      // Check if the inputs are empty
      if (!this.formData.name || !this.formData.phone) {
        this.showToast("Please fill in all fields.");
        return;
      }

      // Validate phone number
      if (!this.validatePhoneNumber()) {
        this.showToast("Please enter a valid phone number.");
        return;
      }

      // If all validations pass, submit the form
      try {
        this.isLoading = true;
        // Send form data to the endpoint
        const response = await fetch(
          // "https://ccendpoints.herokuapp.com/api/v2/m2m/create/customer-call-request",
          "https://lendnode.creditclan.com/mtom/api/create/customer-call-request",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(this.formData),
          }
        );

        // Handle response as needed
        if (response.ok) {
          this.isLoading = false;
          this.showToast("Your request has been successfully submitted");
          setTimeout(() => {
            this.closePopUp();
          }, 1000);
        } else {
          console.error("Failed to submit form");
        }
      } catch (error) {
        console.error("Error submitting form:", error);
      }
    },
    validatePhoneNumber() {
      const phoneNumberPattern = /^(0[789]\d{9})$/;
      return phoneNumberPattern.test(this.formData.phone);
    },
    showToast(message) {
      this.toastMessage = message;
      this.toast = true;
      setTimeout(() => {
        this.toast = false;
      }, 3000); // Hide toast after 3 seconds
    },
  },
};
</script>

<style lang="css" scoped>
.contact-container {
  width: 55vw;
  height: 80vh;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.form-container {
  padding: 0 2em !important;
  overflow-y: auto;
  height: 100%;
}

.form-control {
  border: 1px solid #cfe2ff;
  border-radius: 0.25rem;
  padding: 0.7em 1em;
  width: 100%;
  background-color: #e9f0fa;
}

.submit-button {
  background-color: #eb6c04;
  color: #fff;
  padding: 1em;
  border-radius: 0.25rem;
  border: none;
  width: 100%;
}

.submit-button:hover {
  opacity: 0.7;
  transition: opacity ease-in-out 0.3s;
}

.yellow-bubble-1 {
  border-radius: 100%;
  background: #ffc72e;
  padding: 2em;
}

.yellow-bubble-2 {
  border-radius: 100%;
  background: rgb(252, 209, 92);
  padding: 4em;
}

.yellow-bubble-3 {
  overflow: hidden;
  border-radius: 100%;
  padding: 3em;
}

.notebook {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.gray-text {
  color: #888;
}

@media (max-width: 1024px) {
  .contact-container {
    width: 73vw;
  }
}

@media (max-width: 700px) {
  .contact-container {
    flex-direction: column;
  }

  .img-container {
    display: none;
  }
}

@media (max-width: 500px) {
  .form-container {
    padding: 0 1.5em !important;
  }

  .contact-container {
    width: 93vw;
  }
}

.toastPop {
  position: fixed;
  background: #a1e8a1;
  padding: 20px;
  top: 5rem;
  right: 2rem;
  border-radius: 8px;
  z-index: 99;
}
</style>
