<template>
    <div id="announcement-details"
        style="background-image: none; background-size: cover; background-repeat: no-repeat; background-attachment: fixed; background-position: center -89.2444px;"
        class="announcement-details bg-img parallaxie" data-overlay-dark="5">
        <div class="container">
            <div class="row">
                <div class="col-lg-6 pb-5">
                    <h1 class="title section-main-title">
                        <span class="highlight-orange">Big News Alert</span>
                    </h1>
                    <p class="p mb-0">
                        We are delighted to announce that our company name, as of the 12th of October, 2022, will be
                        changing from Creditclan to Clan. This represents a clean transition from our credit
                        infrastructure days where we drove technology innovation for lenders, to a platform advancing
                        split payment to consumers channels across Africa.
                    </p>
                    <p class="p mb-0">
                        We are proud of what we achieved with the former brand, especially in leading the credit
                        infrastructure revolution in areas of origination, credibility and collections. With many
                        industry firsts in origination widgets, cashflow insights from pdf to name a few.
                    </p>
                    <p class="p mb-0">
                        As we continue to grow our influence within the consumer space, we are happy to see the
                        connections we are making with families. From testimonials on helping protect cashflow with
                        monthly rent to assisting with spit tuition, our customers see us as a close kinsman they can
                        count on. They see us as a clan they can rely on.
                    </p>
                    <p class="p mb-0">
                        As we look forward to this new beginning of changing the split payment and pay later landscape
                        across Africa, driven by the same excellent team who have led us this far, you can be rest
                        assured that we will be there when you need to split your rent, tuition, item finance, etc.
                    </p>
                    <p class="p mb-0">
                        Welcome to clan. We’re family, indeed.
                    </p>
                </div>
                <div class="col-lg-5 offset-lg-1">
                    <div class="testim-box">
                        <div class="head-box">
                            <img style="width: 100%; height: 100%; object-fit: cover;" src="/images/announcement.jpg" alt="">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "Announcement Detail",

}
</script>

<style lang="scss" scoped>
.announcement-details {
    padding: 11rem 0 10rem;
    background: #fff;
    position: relative;
    z-index: 10;
    overflow: hidden;
    width: 100vw;
}

.testim-box {
    position: relative;
    padding: 0px;
    margin-bottom: -120px;
    z-index: 2;
    height: 100%;
}
.head-box{
    height: 100%;
}

.testim-box:before {
    content: "";
    position: absolute;
    left: 0px;
    top: 0px;
    right: 0px;
    bottom: 0px;
    background: #111215;
    z-index: -1;
}

.testim-box .head-box h6 {
    color: #12c2e9;
    font-size: 12px;
    font-weight: 300;
    text-transform: uppercase;
    letter-spacing: 4px;
    margin-bottom: 10px;
}

.testim-box .head-box h4 {
    margin-bottom: 40px;
}

.testim-box:after {
    content: "";
    position: absolute;
    left: -20px;
    top: -20px;
    right: -20px;
    bottom: -20px;
    background: -webkit-linear-gradient(left, #12c2e9, #c471ed, #e07929);
    background: -o-linear-gradient(left, #12c2e9, #c471ed, #e07929);
    background: linear-gradient(to bottom, #12c2e9, #c471ed, #e07929);
    z-index: -4;
}

.sub-bg {
    background: #ECF2F7;
}

.pt-70 {
    padding-top: 70px !important;
}

.pb-70 {
    padding-bottom: 70px !important;
}

@media only screen and (max-width: 478px) {
    .announcement-details {
        padding: 8rem 0 10rem;
    }
}
</style>