<template>
  <div class="container py-2">
     <!-- Loading Indicator -->
    <div v-if="isLoading" class="text-center">
      <i class="fa fa-spinner fa-spin fa-2x"></i> Loading...
    </div>

    <div v-else>
      <!-- <div class="box" v-for="item in items" :key="item.id"> -->
      <div class="box" v-for="item in sortedItems" :key="item.id">
        <Paper>
          <div class="flex-item">
            <div class="">
              <p class="content mb-2 text-capitalize">{{ item.title }}</p>
              <p class="text-muted m-0">
                Posted: {{ item?.updatedAt?.split('T')[0] }}
              </p>
            </div>
            <div class="">
              <button
                @click="openDetails(item)"
                class="toggle btn d-flex gap-2 flex-row-reverse justify-content-start align-items-center"
              >
                <i class="fa fa-arrow-right" aria-hidden="true"></i>
                Apply
              </button>
            </div>
          </div>
        </Paper>
      </div>

    </div>

    <JobDetails :item="current" :isOpen="isDetailsOpen" @close="closeDetails" />
  </div>
</template>

<script>
import JobDetails from './JobDetails.vue'
import Paper from './Paper'
import axios from 'axios'

export default {
  components: {
    Paper,
    JobDetails,
  },
  props: {
    title: String,
    date: String,
    content: String,
    buttonLabel: String,
  },

  data: () => ({
    isDetailsOpen: false,
    current: null,
    items: [],
  }),

  computed: {
  sortedItems() {
    return this.items.slice().sort((a, b) => {
      // Convert updatedAt to Date objects for comparison
      const dateA = new Date(a.updatedAt);
      const dateB = new Date(b.updatedAt);

      // Sort in descending order
      return dateB - dateA;
    });
  },
},
  async mounted() {
    try {
      this.isLoading = true; // Set loading state to true
      const res = await axios.get(
        `https://ccendpoints.herokuapp.com/api/v2/job/jobs?status=${true}`
      );
      this.items = res?.data?.data;
    } finally {
      this.isLoading = false; // Set loading state to false
    }
  },
  methods: {

    openDetails(item) {
      this.isDetailsOpen = true
      this.current = item
      this.disableBodyScroll()
    },
    closeDetails() {
      this.isDetailsOpen = false
      this.current = null
      this.enableBodyScroll()
    },
    disableBodyScroll() {
      document.body.style.overflow = 'hidden'
    },
    enableBodyScroll() {
      document.body.style.overflow = '' // Reset to default value
    },
  },

}
</script>

<style scoped>
/* .container {
  border-bottom: 2px solid #999;
} */
.box {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.btn {
  max-width: 9rem;
}
.content {
  font-weight: 500;
  font-size: 1.1rem;
}
.flex-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
